import helpers from './helpers'

export default {
  getUrl () {
    const location = window.location
    // получаем текущий url (без параметров) и title страницы
    return encodeURIComponent(location.protocol + '//' + location.host + location.pathname)
  },
  getTitle () {
    let pageTitle = document.getElementsByTagName('h1')
    if (pageTitle.length > 0 && pageTitle[0].innerText) {
      pageTitle = pageTitle[0].innerText
    } else {
      pageTitle = document.title
    }

    pageTitle = this.clearTitle(pageTitle)
    return encodeURIComponent(helpers.base64.encode(pageTitle))
  },
  clearTitle (title) {
    title = title.replace('Ё', 'Е').replace('ё', 'е').replace(/[^А-Яа-я\d\s]+/gi, '')
    return title
  },
  getUrlTurbo (u) {
    return encodeURIComponent(u)
  },
  getTitleTurbo (t) {
    return encodeURIComponent(
      helpers.base64.encode(
        this.clearTitle(t)
      )
    )
  }
}
