import cnst from '../../../constant'
import { get, set } from '../message-iframe-data'
import getIosVersion from '../../helpers/get-ios-version'

let scrollPos = 0

export default function (data) {
  if (data.type === cnst.WIDGET_IFRAME.FULLSCREEN) {
    if (data.message.isTurbo) return

    if (data.message.resize && data.message.height) {
      updataWidgetHeight(document.querySelector(`div.gw_${data.id}`), data.message.height)
    }

    const isCheck = get(data.type, data.id)
    const currentStep = isCheck ? isCheck.step : null
    currentStep ? data.message.step = isCheck.step + 1 : data.message.step = 0
    // если событие уже было шлем лесом
    if (
      currentStep === data.message.step &&
      (isCheck ? isCheck.changeStatus : null) === data.message.changeStatus &&
      (isCheck ? isCheck.fullscreenMode : null) === data.message.fullscreenMode
    ) return
    // сохраняем
    set(data.type, data.id, data.message, true)

    const widget = document.querySelector(`div.gw_${data.id}`)
    const width = window.innerWidth
    const height = window.innerHeight
    const message = {
      width,
      height
    }

    if (
      !data.message.fullscreenMode &&
      data.message.changeStatus &&
      !data.message.resize &&
      !(width > height) &&
      width <= cnst.FULLSCREEN_WORKING_WIDTH
    ) {
      const iosVersion = getIosVersion(navigator.userAgent)
      message.setStatus = true
      enableFullscreen(widget)
      sendMessage(widget, message, data)
      // На Ios в сафари при активации фулскрина выдвигается нижняя панель
      // Высота экрана становится меньше, но css этого не учитывает
      // Нужно обновлять значение высоты после актвации
      if (iosVersion) {
        setTimeout(() => {
          sendMessage(widget, {
            width: window.innerWidth,
            height: window.innerHeight,
            setStatus: true
          }, data)
        }, 100)
      }
      return
    }
    if (data.message.fullscreenMode && data.message.changeStatus && !data.message.resize) {
      disableFullscreen(widget, data.message.titleTopIsShow)
      message.setStatus = false
      sendMessage(widget, message, data)
      return
    }

    if (data.message.fullscreenMode && !data.message.changeStatus && data.message.resize) {
      if (width > height || width > cnst.FULLSCREEN_WORKING_WIDTH) {
        disableFullscreen(widget, data.message.titleTopIsShow)
        message.execute = 'disable'
        sendMessage(widget, message, data)
      }
    }
  }
}

function sendMessage (widget, message, data) {
  widget.lastChild.contentWindow.postMessage({
    id: data.id,
    type: data.type,
    message
  }, '*')
}

function updataWidgetHeight (widget, height) {
  widget.style.height = `${height}px`
}

window.grattisHiddenElements = []

/**
 * Функция ищет все элементы,
 * которые потенциально могут перекрыть фулскрин
 * и прячет их
 */
function hiddenElementsFixed () {
  window.grattisHiddenElements = []
  const elems = [...document.body.getElementsByTagName('*')]
  elems.forEach(elem => {
    const style = window.getComputedStyle(elem, null)
    if (style.getPropertyValue('position') === 'fixed' && style.getPropertyValue('visibility') !== 'hidden') {
      elem.style.visibility = 'hidden'
      elem.dataset.gHidden = 'yes'
      window.grattisHiddenElements.push(elem)
    }
  })
}

/**
 * Показываем скрытые элементы
 */
function showHiddenElementsFixed () {
  window.grattisHiddenElements.forEach(elem => {
    elem.style.removeProperty('visibility')
    elem.dataset.gHidden = 'no'
  })
  window.grattisHiddenElements = []
}

function enableFullscreen (widget) {
  hiddenElementsFixed()
  widget.style.position = 'fixed'
  widget.style.top = '0'
  widget.style.left = '0'
  // С таким значением перекрываем рекламу
  widget.style.zIndex = '2147483647'

  // for iOS
  const iosVersion = getIosVersion(navigator.userAgent)
  if (iosVersion) {
    scrollPos = window.scrollY
    document.body.style.position = 'fixed'
    window.scrollPos = scrollPos
    // Таймаут чтобы дать сначала появиться нижней панели в сафари
    setTimeout(() => {
      document.body.style.height = `${window.innerHeight}px`
      widget.style.height = `${window.innerHeight}px`
      updataWidgetHeight(widget, window.innerHeight)
    }, 100)
  } else {
    document.body.style.overflow = 'hidden'
  }
}

function disableFullscreen (widget, titleTopIsShow) {
  showHiddenElementsFixed()
  widget.style.position = 'relative'
  widget.style.height = titleTopIsShow ? '635px' : '565px'
  widget.style.top = '0'
  widget.style.left = '0'
  widget.style.removeProperty('z-index')

  // for iOS
  const iosVersion = getIosVersion(navigator.userAgent)
  if (iosVersion) {
    document.body.style.removeProperty('position')
    window.scrollTo(0, scrollPos)
    scrollPos = 0
  } else {
    document.body.style.removeProperty('overflow')
  }
}
