import setupWidget from './lib/setup-widget'
import cnst from './constant'
import message from './lib/message-iframe'

// Сообщения от виджета
message.init()

if (window[cnst.KEY].length > 0) {
  const widgetId = window[cnst.KEY].shift()
  const widgets = document.getElementsByClassName('gw_' + widgetId) || []

  for (let i = 0; i < widgets.length; i++) {
    const widgetContainer = widgets[i]
    if (widgetContainer.dataset.isSet !== 'yes') {
      wrapNoIndex(widgetContainer)
      widgetContainer.dataset.isSet = 'yes'
      setupWidget(widgetId, widgetContainer)
    }
  }
}

function wrapNoIndex (widget) {
  const wrapper = document.createElement('noindex')
  widget.parentNode.insertBefore(wrapper, widget)
  wrapper.appendChild(widget)
}
